import './terms.css';


const Terms = () => {
    return (<div className="terms-container">
        <h1>Terms and conditions of use</h1>
        <p>Please read the terms and conditions carefully before you use this website. Your
            use of the site assumes that you agree to these terms, as well as our &nbsp;
            <a href="">privacy policy</a>. <br /><br />
            BiasCoin may update or modify these terms at any time.
        </p>
        <h1>BiasCoin does not provide investment advice</h1>
        <p>
            BiasCoin does not provide investment advice. The content is for educational
            purposes only. We encourage you to seek advice from a qualified financial advisor
            before making investments or financial decisions.
        </p>
        <h1>BiasCoin does not guarantee accuracy of data</h1>
        <p>Our stock data is derived from &nbsp; <a href="">several different sources</a>.
            Although every effort is made to ensure accuracy, it can not be guaranteed in all
            cases. <br /><br />
            BiasCoin encourages you to verify that any data is accurate by analyzing
            a company's SEC filings before making investing decisions. <br /><br />
            If you notice any inaccuracies or discrepancies in the data, please send us a 
            message via our &nbsp;<a href="">contact form</a>. <br /><br />
            In addition, some of the data may be delayed by 15 minutes or more. 
        </p>
        <h1>Use of our content</h1>
        <p>You are not allowed at any time to republish content that is found on this application
            without the explicit permission of Martingale Stochastic Process LLC.
        </p>
        <h1>Liability of BiasCoin and its authors</h1>
        <p>
            BiasCoin, an application owned by Martingale Stochastic Process LLC, along with 
            the members of the company as well as the authors, developers, and designers of the 
            application, shall not be held liable to any party for any damages arising from the use 
            of the material or information on the website and application.
        </p>
        <h1>Funding</h1>
        <p>This website is funded by the personal investment of the members of 
            Martingale Stochastic Process LLC. This may change in the future with funding sourced
            from third-party advertisements.
        </p>
        <h1>Advertisements</h1>
        <p>This site and application may contain advertisements that may lead to other websites.
            BiasCoin is not affiliated with these websites and these ads do not constitute
            endorsements of the products or services offered.
        </p>
        <h1>Links to others websites</h1>
        <p>The content on this website and application may contain links to other websites, or 
            embed content from other third-party websites. BiasCoin does not endorse the
            content on these websites and will not be held responsible for their content.
        </p>
        <h1>Order process and payments</h1>
        <p>When you purchase a product or subscription from Bias Coin, the order process 
            will be conducted by Martingale Stochastic Process LLC, the parent company that owns 
            BiasCoin. Martingale Stochastic Process LLC is the "Merchant of Record" for all 
            of our subscriptions and payments. All customer service inquiries and returns are handled
            by Martingale Stohastic Process LLC.
        </p>
        <h1>Cancellations</h1>
        <p>If you sign up for a free trial, then you will not be billed until the free trial period ends. 
            However, you can cancel your subscription at any time before the next billing date and then
            you will not be charged. <br /><br />
            If you sign up for a free trial, then we will send you an email reminder several days before
            the trial period ends to give you the option to cancel before being billed. <br /><br />
            The fastest way to cancel your free trial or paid subscription is to go to the My Account 
            page and click "Cancel Subscription".
        </p>
        <h1>Refunds</h1>
        <p>BiasCoin also offers a 30-day money back guarantee on any purchases or subscription 
            payments, no questions asked. If you are not 100% satisfied then you will get a full refund 
            on any payments made in the previous 30 days. <br /><br />
            Send a message via the &nbsp;<a href="">contact form</a> or email us directly at 
            &nbsp;<a href="">contact@biascoin.com</a> to cancel your subscription or request a refund.
        </p>
        <h1>Privacy</h1>
        <p>BiasCoin collects information about your use of the site. We may also use third-party
            tools and services that collect and store data about you. Read our <a href="">privacy policy</a> for more details.
        </p>
        <h1>Complete agreement</h1>
        <p>These terms and conditions of use, as well as our <a href="">privacy policy</a>, constitute the 
        full agreement between you and BiasCoin.</p>
        <h1>Ownership</h1>
        <p>BiasCoin.com and BiasCoin, the registered trademark, are owned by Martingale Stochastic 
            Process LLC, a company registered in the United States of America.
        </p>
        <h1>Contact</h1>
        <p>
            If you have questions about our terms of use or the privacy policy, use the <a href="">contact form</a> to send a message to the site administrator or send an email 
            directly to <a href="">contact@biascoin.com</a>.
        </p>
        <br />
        <br />
        <br />
    </div>);
}

export default Terms;