

const DisplayLowCostOptions = ({ symbol, data }) => {
    
    if (!data.length) { 
        return (<>
        <h3 style={{ textAlign: "center", display: "block", margin: "0 auto", border: "solid 1px black", maxWidth: "800px", backgroundColor: "lightgray" }}>Lowest Cost Hedge</h3>
        </>);
    }
    return (<>
    <h3 style={{ textAlign: "center", display: "block", margin: "0 auto", border: "solid 1px black", maxWidth: "800px", backgroundColor: "lightgray" }}>Lowest Cost Hedge</h3>
    <div className="lowcostoptions-container">
        <div>
            <div>{data[0].strike} {data[0].optionType} {data[0].expiration}</div>
            <div>Last Price: {data[0].lastPrice.toFixed(2)}</div>
            <div>&Delta; {data[0].delta.toFixed(2)}</div>
            <div>Total &Delta; To Hedge {data[0].delta.toFixed(2)}</div>
            <div>Quantity: {data[0].quantity}</div>
            <div>Cost: {data[0].cost}</div>
            <div></div>
            <div></div>
        </div>
        <div>
            <div>{data[1].strike} {data[1].optionType} {data[1].expiration}</div>
            <div>Last Price: {data[1].lastPrice.toFixed(2)}</div>
            <div>&Delta; {data[1].delta.toFixed(2)}</div>
            <div>Total &Delta; Hedged {(data[1].delta.toFixed(2) * data[1].quantity.toFixed(0)).toFixed(2)}</div>
            <div>Quantity: {data[1].quantity.toFixed(2)}</div>
            <div>Cost of Hedge: {data[1].cost.toFixed(2)}</div>
            <div></div>
            <div></div>
        </div>
    </div>
    {/* <hr style={{ marginTop: "10px" }} /> */}
    </>
    );
}

export default DisplayLowCostOptions;