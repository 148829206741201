import { QUERY_ACCOUNT } from '../../../utils/queries';
import { CHECKOUT, USER_UNSUBSCRIBE } from '../../../utils/mutations';
import { useQuery, useMutation } from '@apollo/client';
import { useState } from 'react';

const AccountInfo = ({ email, isSubscribed }) => {
    const [formState, setFormState] = useState({ priceId: 'price_1KxfdWEiOe39o9udKVWBPhig' });
    const [checkoutMutation] = useMutation(CHECKOUT);
    const [unsubscribeMutation] = useMutation(USER_UNSUBSCRIBE);
    var subscriptionEl;
    console.log(isSubscribed)
    const accountQuery = useQuery(QUERY_ACCOUNT,
        {
            variables: { email: email },
            errorPolicy: "all"
        });
    if (accountQuery.loading) {
        return (<div>Loading</div>);
    }
    const subscribeClicked = async (ev) => {
        ev.preventDefault();

        const { data } = await checkoutMutation({ variables: { email: email, priceId: formState.priceId } });
        console.log(data.subscribeUser.sessionUrl)
        console.log(formState.priceId);
        window.location.replace(data.subscribeUser.sessionUrl);
    }
    const unsubscribeClicked = async (ev) => {
        ev.preventDefault();

        await unsubscribeMutation({ variables: { email: email, priceId: formState.priceId } });
        window.location.replace('/logout');
        // window.location.replace('/account');
        console.log('unsubscribe clicked');
    }

    if (isSubscribed === 'true') {
        subscriptionEl = <div className="unsubscribe-card">
            <div>Subscribed</div>
            <form onSubmit={unsubscribeClicked} >
                <input type="hidden" name="priceId" />
                <button type="submit">Unsubscribe</button>
            </form>
        </div>

    } else {
        subscriptionEl = <div>
            <div className="subscription-card">
                <div>Subscription Features</div>
                <div>Access to Algorithmic Trading Features</div>
                <div>Price: $20.00 per month</div>
                <form onSubmit={subscribeClicked} >
                    <input type="hidden" name="priceId" />
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        </div>
    }


    return (<div>
        <div>Account</div>


        {subscriptionEl}
        <div>Email: {accountQuery.data.user.email}</div>
        <div>Full Name: {accountQuery.data.user.fullName.firstName} {accountQuery.data.user.fullName.lastName}</div>
    </div>);
}

export default AccountInfo;