import Auth from '../../utils/auth';


const Logout = () => {

    return (<div>
        <input hidden onLoad={Auth.logout()} />
    </div>);
}

export default Logout;