import './searchstock.css';
import { useState } from 'react';

const SearchStock = () => {
    const [formState, setFormState] = useState({ symbol: '' });

    const handleChange = (ev) => {
        const { name, value } = ev.target;

        setFormState({
            ...formState,
            [name]: value
        });
    }

    const handleFormSubmit = (ev) => {
        ev.preventDefault();

        try {
            console.log(formState.symbol)
            window.location.replace(`/${formState.symbol}`)
        } catch (e) {
            console.error(e);
        }
        setFormState({symbol: ''});
    }

    return (<form className="searchstock" onSubmit={handleFormSubmit}>
        <h2>Search Stocks</h2>
        <input type="search" placeholder="Ex: MSFT" name="symbol" value={formState.symbol} onChange={handleChange} />
    </form>);
}

export default SearchStock;