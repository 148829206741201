import HeaderVideo from './purpleSky.mp4';
import './header.css';

function Header() {

    return (
        <a href="/" className="header">
            <header>
                <div className="title">
                    <div>BiasCoin</div>
                    <p>Stay biased!</p>
                </div>

                <video autoPlay muted loop={true} >
                    <source src={HeaderVideo} type="video/mp4" ></source>
                </video>
            </header>
        </a>

    );
}

export default Header;