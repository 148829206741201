import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  scales: {
    x: {
      ticks: {
        callback: function(value, index) {
          // Display every 8th label
          return index % 8 === 0 ? this.getLabelForValue(value) : '';
        },
      },
    },
    y: {
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: false, // This disables the legend
    },
  },
};


const StockChart = ({ dataset, symbol }) => {
    const labels = [];
    const dataPoints = [];
    
    for (let i in dataset) {
        labels.push(dataset[i].timestamp.split('T')[0]);
        dataPoints.push(dataset[i].c);
    }
    const data = {
        labels: labels,
        datasets: [{
            label: `${symbol}`,
            data: dataPoints,
            fill: false,
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 3,
            pointRadius: 0
        }]
    };

    return (
      <>
      <h3 style={{ textAlign: "center", display: "block", margin: "0 auto", border: "solid 1px black", maxWidth: "800px", backgroundColor: "lightgray" }}>Chart</h3>
      <div className="stockchart-container">
          
            <div>
                <Line data={data} options={options} />
            </div>
        </div>
      </>
        
    );
}

export default StockChart;