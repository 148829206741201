import { gql } from '@apollo/client';

export const CHECKOUT = gql`
    mutation checkout($email: String!, $priceId: String!) {
        subscribeUser(email: $email, priceId: $priceId) {
            sessionUrl
            user {
                email
            }
        }
    }
`;

export const USER_UNSUBSCRIBE = gql`
    mutation unsubscribeUser($email: String!, $priceId: String!) {
        unsubscribeUser(email: $email, priceId: $priceId) {
            token
            user {
                email
            }
        }
    }
`;
export const USER_SUBSCRIBE = gql`
    mutation subscribeUser($email: String!) {
        subscribeUser(email: $email) {
            token
            user {
                email
            }
        }
    }
`;

export const UPDATE_HAND = gql`
    mutation updateHand($email: String!, $newCard: String) {
        updateHand(email: $email, newCard: $newCard) {
            email
        }
    }
`;

export const ADD_USER = gql`
    mutation addUser($email: String!, $password: String!) {
        addUser(email: $email, password: $password) {
            token
            user {
                _id
                email
                isSubscribed
            }
        }
    }
`;

export const LOGIN_USER = gql`
    mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            token
            user {
                _id
                email
                isSubscribed
            }
        }
    }
`;