import { useQuery } from '@apollo/client';
import { GET_PREDICTION } from '../../utils/queries';
import { timeOptions } from '../../utils/timeOptions';

const DisplayStockInfo = ({ symbol, price }) => {
    const predictionQuery = useQuery(GET_PREDICTION, { variables: { symbol: symbol } });

    if (predictionQuery.loading) {
        return (<></>);
    }


    let lastUpdated = "", 
        linearRegressionPrice = "", linearRegressionRange = "",
        arimaPrice = "", arimaRange = "",
        lstmPrice = "", lstmRange = "", borderColorLR = "black",
        borderColorAR = "black", borderColorLS = "black";

    if (predictionQuery.data.getPrediction !== null) {
        linearRegressionPrice = parseFloat(predictionQuery.data.getPrediction.linearRegressionPrice);
        linearRegressionRange = predictionQuery.data.getPrediction.linearRegressionRange;
        arimaPrice = parseFloat(predictionQuery.data.getPrediction.arimaPrice);
        arimaRange = predictionQuery.data.getPrediction.arimaRange;
        lstmPrice = parseFloat(predictionQuery.data.getPrediction.lstmPrice);
        lstmRange = predictionQuery.data.getPrediction.lstmRange;
        lastUpdated = new Intl.DateTimeFormat('en-US', timeOptions).format(new Date(predictionQuery.data.getPrediction.lastUpdated));
        borderColorLR = linearRegressionPrice < parseFloat(price) ? "black" : "black";
        borderColorAR = arimaPrice < price ? "black" : "black";
        borderColorLS = lstmPrice < price ? "black" : "black";
    }

    return (<>
        <h3 style={{ textAlign: "center", display: "block", margin: "0 auto", border: "solid 1px black", maxWidth: "800px", backgroundColor: "lightgray" }}>Predictions</h3>
        <div className="prediction-container">
            <div className="prediction" style={{ border: `6px dashed ${borderColorLR}` }}>
                <div>Linear Regression</div>
                <div>Next Close Prediction: {linearRegressionPrice}</div>
                <div>Next Close Range Prediction: {linearRegressionRange}</div>
                <div>Last Updated: {lastUpdated}</div>
            </div>
            <div className="prediction" style={{ border: `6px dashed ${borderColorAR}` }}>
                <div>ARIMA</div>
                <div>Next Close Prediction: {arimaPrice}</div>
                <div>Next Close Range Prediction: {arimaRange}</div>
                <div>Last Updated: {lastUpdated}</div>
            </div>
            <div className="prediction" style={{ border: `6px dashed ${borderColorLS}` }}>
                <div>LSTM</div>
                <div>Next Close Prediction: {lstmPrice}</div>
                <div>Next Close Range Prediction: {lstmRange}</div>
                <div>Last Updated: {lastUpdated}</div>
            </div>
        </div>
    </>);
}

export default DisplayStockInfo;