import React, { useState } from 'react';
import { timeOptions } from '../../utils/timeOptions';

const OptionChainCards = ({ optionChainData }) => {
    const [contentIndexes, setContentIndexes] = useState(
        optionChainData.map(() => 0) // Initialize content index for each card
    );

    // Separate arrays for calls and puts
    let callHtml = [];
    let putHtml = [];
   
    // Iterate through optionChainData and push calls and puts to their respective arrays
    optionChainData.forEach((option, i) => {
        let lastUpdated = new Intl.DateTimeFormat('en-US', timeOptions).format(new Date(option.lastUpdated));
        let midPrice = option.midPrice ?? 0;


        let itmProbability = option.itmProbability ?? 0;
        let stockPrice = option.stockPrice ?? 0;
        let theoreticalPrice = option.theoreticalPrice ?? 0;
        let riskFreeRate = option.riskFreeRate ?? 0;
        let openInterest = option.openInterest ?? 0;

        let delta = option.delta ?? 0;
        let fetchedDelta = option.fetchedDelta ?? 0;
        let displayedDelta = option.optionType === 'call' ? Math.max(delta, fetchedDelta) : Math.min(delta, fetchedDelta);
        let gamma = option.gamma ?? 0;
        let fetchedGamma = option.fetchedGamma ?? 0;
        let displayedGamma = Math.max(gamma, fetchedGamma);
        let impliedVolatility = option.impliedVolatility ?? 0;
        let fetchedImpliedVolatility = option.fetchedImpliedVolatility ?? 0;
        let displayedImpliedVolatility = Math.max(impliedVolatility, fetchedImpliedVolatility);
        let theta = option.theta ?? 0;
        let fetchedTheta = option.fetchedTheta ?? 0;
        let displayedTheta = Math.min(theta, fetchedTheta);
        let vega = option.vega ?? 0;
        let fetchedVega = option.fetchedVega ?? 0;
        let displayedVega = Math.max(vega, fetchedVega);
        let rho = option.rho ?? 0;
        let fetchedRho = option.fetchedRho ?? 0;
        let displayedRho = option.optionType === 'call' ? Math.max(rho, fetchedRho) : Math.min(rho, fetchedRho);

        let lastFiveDays = option.lastFiveDays;
        // let mostRecentDay = option.mostRecentDay;
        
        let intrinsicValue = option.intrinsicValue ?? 0;
        let extrinsicValue = option.extrinsicValue ?? 0;
        let realizedVolatility = option.realizedVolatility ?? 0;

        const contents = [
            {
                data: (
                    <>
                        <p><strong>Mid Price:</strong> {midPrice.toFixed(2)}</p>
                        <p><strong>&Delta; Delta:</strong> {displayedDelta.toFixed(2)}</p>
                        <p><strong>&sigma; Implied Volatility:</strong> {displayedImpliedVolatility.toFixed(3)}</p>
                        <p><strong>&Theta; Theta:</strong> {displayedTheta.toFixed(2)}</p>
                        <p><strong>Open Interest:</strong> {openInterest}</p>
                    </>
                )
            },
            {
                data: (<>
                    <p><strong>{new Date(lastFiveDays[0].t).toJSON().slice(0,10)} Volume</strong> {lastFiveDays[0].v}</p>
                    <p><strong>{new Date(lastFiveDays[1].t).toJSON().slice(0,10)} Volume</strong> {lastFiveDays[1].v}</p>
                    <p><strong>{new Date(lastFiveDays[2].t).toJSON().slice(0,10)} Volume</strong> {lastFiveDays[2].v}</p>
                    <p><strong>{new Date(lastFiveDays[3].t).toJSON().slice(0,10)} Volume</strong> {lastFiveDays[3].v}</p>
                    <p><strong>{new Date(lastFiveDays[4].t).toJSON().slice(0,10)} Volume</strong> {lastFiveDays[4].v}</p>
                </>)
            },
            {
                data: (<>
                    <p><strong>&Gamma; Gamma:</strong> {displayedGamma.toFixed(3)}</p>
                    <p><strong>&nu; Vega:</strong> {displayedVega.toFixed(3)}</p>
                    <p><strong>&rho; Rho:</strong> {displayedRho.toFixed(2)}</p>
                    <p><strong>Stock Price:</strong> {stockPrice}</p>
                    <p><strong>Risk Free Rate:</strong> {riskFreeRate}</p>
                </>)
            },
            {
                data: (<>
                    
                    {/* <p><strong>Change:</strong> {mostRecentDay.change}</p>
                    <p><strong>Vwap:</strong> {mostRecentDay.vwap}</p>
                    <p><strong>High:</strong> {mostRecentDay.high}</p>
                    <p><strong>Low:</strong> {mostRecentDay.low}</p> */}
                    </>)
            },
            {
                data: (<>
                <p><strong>Realized Volatility:</strong> {realizedVolatility.toFixed(2)}</p>
                    <p><strong>In-The-Money Probability:</strong> {(itmProbability * 100).toFixed(2)}%</p>
                    <p><strong>Intrinsic Value:</strong> {intrinsicValue}</p>
                    <p><strong>Extrinsic Value:</strong> {extrinsicValue}</p>
                    <p><strong>Last Updated:</strong>{lastUpdated.slice(0,23)}</p>
                    </>)
            }
        ];
        // Change this value to adjust the number of pages
        const paginationLimit = contents.length;
        // Pagination functions
        const nextContent = (i) => {
            setContentIndexes((prevIndexes) =>
                prevIndexes.map((index, idx) =>
                    idx === i ? (index + 1) % paginationLimit : index
                )
            );
        };

        const previousContent = (i) => {
            setContentIndexes((prevIndexes) =>
                prevIndexes.map((index, idx) =>
                    idx === i ? (index - 1 + paginationLimit) % paginationLimit : index
                )
            );
        };
        const card = {
            id: option.strike, // Unique id based on strike
            key: `${option.optionType}-${i}`, // Key for the card
            content: (
                <div className="optionchain" key={`${option.optionType}-${i}`}>
                    <div className="optionchain-header">
                        <div>{option.strike} {option.optionType} {option.expiration}</div>
                    </div>
                    <div className="optionchain-content">
                        {contents[contentIndexes[i]].data}
                        <div className="button-container">
                            {contentIndexes[i] > 0 && (
                                <button onClick={() => previousContent(i)}>Previous</button>
                            )}
                            {contentIndexes[i] < contents.length - 1 && (
                                <button onClick={() => nextContent(i)}>Next</button>
                            )}
                        </div>
                    </div>
                </div>
            )
        };

        // Separate calls and puts
        if (option.optionType === 'call') {
            callHtml.push(card);
        } else if (option.optionType === 'put') {
            putHtml.push(card);
        }
    });

    // Sorting logic for calls and puts
    const sortedCalls = callHtml.sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));
    const sortedPuts = putHtml.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));



    return (
        <div className="optionchain-container">

            <div className="option-chain-calls">
                {sortedCalls.map(card => card.content)}
            </div>


            <div className="option-chain-puts">
                {sortedPuts.map(card => card.content)}
            </div>
        </div>
    );
};

export default OptionChainCards;