


const LoadingComponent = ({ symbol }) => {
    return (
        <div>
            <div className="company-title">
                <h2>{symbol}</h2>
                <h2></h2>
                <h2>CIK - </h2>
                <h2></h2>
            </div>
            <h3 style={{ textAlign: "center", display: "block", margin: "0 auto", border: "solid 1px black", maxWidth: "800px", backgroundColor: "lightgray" }}>Chart</h3>
            <div className="stockchart-container">
                <div>
                </div>
            </div>
            <h3 style={{ textAlign: "center", display: "block", margin: "0 auto", border: "solid 1px black", maxWidth: "800px", backgroundColor: "lightgray" }}>Prediction</h3>
            <div className="prediction-container">
                <div>Next Close Prediction: </div>
                <div>Next Close Range Prediction: </div>
                <div>Friday Close Prediction: </div>
                <div>Friday Close Range Prediction: </div>
                <div>Last Updated: </div>
            </div>
            <h3 style={{ textAlign: "center", display: "block", margin: "0 auto", border: "solid 1px black", maxWidth: "800px", backgroundColor: "lightgray" }}>Lowest Cost Hedge</h3>
            <div className="lowcostoptions-container">
                <div>
                    <div></div>
                    <div>Last Price: </div>
                    <div>&Delta; </div>
                    <div>Total &Delta; To Hedge </div>
                    <div>Quantity: </div>
                    <div>Cost: </div>
                    <div></div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                    <div>Last Price: </div>
                    <div>&Delta; </div>
                    <div>Total &Delta; Hedged </div>
                    <div>Quantity: </div>
                    <div>Cost of Hedge: </div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <h3 style={{ textAlign: "center", display: "block", margin: "0 auto", border: "solid 1px black", maxWidth: "800px", backgroundColor: "lightgray" }}>Option Chain</h3>
            <div className="optionchain-container">

            </div>
        </div>
    );
}

export default LoadingComponent;