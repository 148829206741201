import './account.css';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { QUERY_ACCOUNT } from '../../utils/queries';
import { CHECK_SUBSCRIPTION } from '../../utils/queries';
import AccountInfo from './AccountInfo';

const Account = ({ loggedin, email, isSubscribed }) => {
    console.log(email)
    
    
    if (!loggedin) {
        return (<div>Not logged in.</div>);
    }
    
    return (<div>
        <AccountInfo email={email} isSubscribed={isSubscribed} />
    </div>);
}

export default Account;