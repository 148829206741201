import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_USER } from '../../utils/mutations';


const SignUpForm = ({ loggedin }) => {
    const [formState, setFormState] = useState({ email: '', password: '' });
    const [addUser, { error }] = useMutation(ADD_USER); // Removed unused `data` from here

    // If already logged in, display a message
    if (loggedin) {
        return (<div>Already Logged In</div>);
    }

    // Handle input change and update form state
    const handleChange = (ev) => {
        const { name, value } = ev.target;
        setFormState({
            ...formState,
            [name]: value
        });
    }

    // Handle form submission
    const handleFormSubmit = async (ev) => {
        ev.preventDefault();

        try {
            const { data } = await addUser({
                variables: { ...formState }
            });

            // Clear the form after success
            setFormState({ email: '', password: '' });

            // Redirect on successful registration
            window.location.replace('/');
        } catch (e) {
            // Log the error to the console

            // Clear the form and redirect on failure
            setFormState({ email: '', password: '' });
            window.location.replace('/');
        }
    }

    return (
        <div className="signup">
            <h2>Sign Up Form</h2>
            <form onSubmit={handleFormSubmit}>
                <label htmlFor="email">Email:</label>
                <input
                    name="email"
                    type="email"
                    value={formState.email}
                    onChange={handleChange}
                />
                <label htmlFor="password">Password:</label>
                <input
                    name="password"
                    type="password"
                    value={formState.password}
                    onChange={handleChange}
                />
                <button type="submit">Sign Up</button>
            </form>

            {/* Render an error message if mutation fails */}
            {error && <div style={{ color: 'red' }}>Error: {error.message}</div>}
        </div>
    );
}

export default SignUpForm;
