import { useQuery } from '@apollo/client';
import { GET_OPTIONCHAIN } from '../../utils/queries';
import { timeOptions } from '../../utils/timeOptions';
import {
    useState

} from 'react';

import OptionChainCards from './OptionChainCards';

const DisplayOption = ({ symbol }) => {
    const optionChainQuery = useQuery(GET_OPTIONCHAIN, { variables: { symbol: symbol } });
    const [contentIndex, setContentIndex] = useState(0);

    if (optionChainQuery.loading) {
        return (<></>);
    }

    
    let optionChainData = [];
    let sortedCalls = [];
    let sortedPuts = [];
    const callHtml = [];
    const putHtml = [];
    let delta, gamma, vega, theta, stockPrice, riskFreeRate, openInterest, midPrice, theoreticalPrice, impliedVolatility, breakEvenPrice, itmProbability, lastUpdated, contents;

    const nextContent = () => {
        setContentIndex((contentIndex + 1) % contents.length);
    };

    const previousContent = () => {
        setContentIndex((contentIndex - 1 + contents.length) % contents.length);
    };



    if (optionChainQuery.data.getOptionChain !== null) {
        optionChainData = optionChainQuery.data.getOptionChain;



        for (let i in optionChainData) {
            lastUpdated = new Intl.DateTimeFormat('en-US', timeOptions).format(new Date(optionChainData[i].lastUpdated));
            midPrice = optionChainData[i].midPrice ?? 0;
            impliedVolatility = optionChainData[i].impliedVolatility ?? 0;
            breakEvenPrice = optionChainData[i].breakEvenPrice ?? 0;
            itmProbability = optionChainData[i].itmProbability ?? 0;
            vega = optionChainData[i].vega ?? 0;
            stockPrice = optionChainData[i].stockPrice ?? 0;
            theoreticalPrice = optionChainData[i].theoreticalPrice ?? 0;
            riskFreeRate = optionChainData[i].riskFreeRate ?? 0;
            openInterest = optionChainData[i].openInterest ?? 0;
            theta = optionChainData[i].theta ?? 0;
            contents = [{
                data: (
                    <>
                        <p><strong>Mid Price:</strong> {midPrice}</p>
                        <p><strong>&Delta; Delta:</strong> {delta}</p>
                        <p><strong>&sigma; Implied Volatility:</strong> {impliedVolatility}</p>
                        <p><strong>&Gamma; Gamma:</strong> {gamma}</p>
                        <p><strong>&nu; Vega:</strong> {vega}</p>
                        <p><strong>&theta; Theta:</strong> {theta}</p>
                        <p><strong>Open Interest:</strong> {openInterest}</p>
                    </>
                )
            },
            {
                data: (<></>)
            },
            {
                data: (<></>)
            }];

            if (optionChainData[i].optionType === 'call') {
                delta = optionChainData[i].delta ?? 0;
                gamma = optionChainData[i].gamma ?? 0;
                callHtml.push({
                    id: optionChainData[i].strike, key: `call-${i}`, content: <div className="optionchain" key={`call-${i}`}>
                        <div className="optionchain-header">
                            <div>{optionChainData[i].strike} {optionChainData[i].optionType} {optionChainData[i].expiration}</div>
                        </div>
                        <div className="optionchain-content">
                            {contents[contentIndex].data}
                            <div className="button-container">
                                {contentIndex > 0 && (
                                    <button onClick={previousContent}>Previous</button>
                                )}
                                {contentIndex < contents.length - 1 && (
                                    <button onClick={nextContent}>Next</button>
                                )}
                            </div>


                            



                        </div>
                    </div>
                });
            } else if (optionChainData[i].optionType === 'put') {
                delta = optionChainData[i].delta ?? 0;
                gamma = optionChainData[i].gamma ?? 0;
                putHtml.push({
                    id: optionChainData[i].strike, key: `put-${i}`, content: <div className="optionchain">
                        <div className="optionchain-header">
                            <div>{optionChainData[i].strike} {optionChainData[i].optionType} {optionChainData[i].expiration}</div>
                        </div>
                        <div className="optionchain-content">
                            <p>&Delta; Delta: {delta.toFixed(3)}</p>
                            <p>&Gamma; Gamma: {gamma.toFixed(5)}</p>
                            <p>Last Price: {midPrice.toFixed(2)}</p>
                            <p>Implied Volatility: {impliedVolatility.toFixed(3)}</p>
                            <p>Break-Even Price: {breakEvenPrice.toFixed(2)}</p>
                            <p>ITM Probability: {itmProbability.toFixed(3)}</p>
                            <p>As of: {lastUpdated}</p>
                        </div>
                        {/* <p>{ lastUpdated }</p> */}
                            {/* <p><strong>Risk-Free Rate:</strong> { riskFreeRate }</p>
                            <p><strong>Stock Price:</strong> { stockPrice }</p> */}
                            {/* <h3>Most Recent Day</h3> */}
                            {/* <p><strong>Change:</strong> {{ mostRecentDay.change }}</p>
                            <p><strong>Change Percent:</strong> {{ mostRecentDay.change_percent }}</p>
                            <p><strong>Close:</strong> {{ mostRecentDay.close }}</p>
                            <p><strong>Volume:</strong> {{ mostRecentDay.volume }}</p> */}

                            {/* <h3>Last Five Days</h3> */}
                            {/* <ul>
                                {{ #each lastFiveDays }}
                                <li><strong>Date:</strong> {{ t }}, Open: {{ o }}, Close: {{ c }}</li>
                                {{/ each}}
                            </ul> */}
                    </div>
                });
            }
        }

        
    };

    return (<>
        <h3 style={{ textAlign: "center", display: "block", margin: "0 auto", border: "solid 1px black", maxWidth: "800px", backgroundColor: "lightgray" }}>Option Chain</h3>
        <OptionChainCards optionChainData={optionChainQuery.data.getOptionChain} />
    </>);
}

export default DisplayOption;