import { useQuery } from '@apollo/client';
import { GET_VOLATILITY } from '../../utils/queries';

const VolatilityContainer = () => {
    const volatilityQuery = useQuery(GET_VOLATILITY, {});
    if (volatilityQuery.loading) {
        return (<></>);
    }
    const vix = volatilityQuery.data.getVolatility[0].vix;
    const realized = volatilityQuery.data.getVolatility[0].realized;
    const skew = volatilityQuery.data.getVolatility[0].skew;
    return (<div className="volatility-container">
        <div>VIX: {vix}</div>
        <div>Realized: {realized}</div>
        <div>SKEW: {skew}</div>
    </div>);
}

export default VolatilityContainer;